import { Status } from '~/constants/general/Status'
import { useSelectedCompanyForWholeAppStatus } from '~/composables/selectedCompanyForWholeApp/fetch/selectedCompanyForWholeAppStatus'
import { ISearchableCompanyFields } from '~/repository/modules/company/types/companyTypes'
import IGenericErrorApiTemplate, { IErrorKeyValue, IGeneralErrorApi } from '~/store/common/IGenericErrorApiTemplate'
import { useAuthStore } from '~/store/auth/auth'
import { ExtrasFetch, HeadersAuthentication, IExtrasFetch } from '~/repository/interfaces/IExtrasFetch'
import { ISuccessResponseGetAll } from '~/repository/interfaces/ISuccessResponseGetAll'
import { ICompany } from '~/store/companies/types/companyTypes'

export const actions = {
  async searchCompanies(body: ISearchableCompanyFields, signal: AbortSignal | null) {
    this.setApiErrors({
      status: 0,
      errors: {},
      formattedErrorList: [],
      title: '',
    })

    const selectedCompanyForWholeAppStatus = useSelectedCompanyForWholeAppStatus()
    const authStore = useAuthStore()
    const token = authStore.token
    const { $api } = useNuxtApp()
    let response
    try {
      const credentials: ISearchableCompanyFields = {
        ...body,
      }
      selectedCompanyForWholeAppStatus.changeSelectedCompanyForWholeAppStatus(Status.LOADING)
      const headers: IExtrasFetch = new ExtrasFetch(
        new HeadersAuthentication(`Bearer ${token}`),
        // @ts-ignore
        // eslint-disable-next-line require-await
        async ({ request, response, _ }) => {
          // eslint-disable-next-line no-console
          console.log('[fetch response error]', request, response.status, response.data)
          this.setApiErrors(response._data)
        },
      )

      response = await $api.companiesRepository.getAll(headers, credentials, signal)
      // eslint-disable-next-line no-console
      console.log(response)
      const typedResponse: ISuccessResponseGetAll<ICompany> = response as ISuccessResponseGetAll<ICompany>
      this.setFoundCompanies(typedResponse)
      selectedCompanyForWholeAppStatus.changeSelectedCompanyForWholeAppStatus(Status.SUCCESS)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      selectedCompanyForWholeAppStatus.changeSelectedCompanyForWholeAppStatus(Status.FAILED)
    }
  },

  setSelectedCompany(company: ICompany | null) {
    // @ts-ignore
    this.selectedCompany = company
  },

  setFoundCompanies(companies: ISuccessResponseGetAll<ICompany>) {
    // @ts-ignore
    this.foundCompanies = companies.items
  },
  setApiErrors(errors: IGenericErrorApiTemplate | IGeneralErrorApi) {
    // eslint-disable-next-line no-prototype-builtins
    if (errors.hasOwnProperty('Detail')) {
      errors = errors as IGeneralErrorApi
      errors.formattedErrorList = [
        {
          errorTitle: errors.Title,
          errorValues: [errors.Detail],
        } as IErrorKeyValue,
      ]
    } else if ('errors' in errors) {
      errors = errors as IGenericErrorApiTemplate
      const errorsObject = errors.errors
      const arrays = Object.entries(errorsObject)
      errors.formattedErrorList = arrays.map((x) => {
        return {
          errorTitle: x[0],
          errorValues: x[1],
        } as IErrorKeyValue
      })
    }
    // @ts-ignore
    this.errors = errors
  },
  reset() {
    // @ts-ignore
    this.setSelectedCompany(null)
  },
}
